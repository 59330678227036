.error_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 33px;
    margin-bottom: 40px;
    gap: 20px;
}

.error_heading {
    color: #000;
    font-size: 23px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.error_heading_title {
    color: #000;
    font-size: 120px;
    font-weight: 200; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
  }

.error_subheading {
    color: #5F5F5F;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 154.523%;
    max-width: 550px;
}

.error_btn_area {
    display: flex;
    gap: 20px;
}